<template>
  <div>
    <div class="row">
      <div :class="[scenarioViewGroup==='L' ? 'col-12' : 'col-xs-12 col-sm-6 col-md-4 col-lg-4', 'scenario-list']"> 
        <c-table
          ref="table"
          title="평가대상 목록"
          :columns="gridColumns"
          :gridHeight="setheight"
          :data="actionData.scenarioList"
          :merge="scenarioViewGroup==='L' ? grid.merge : []"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="false"
          :isExcelDown="scenarioViewGroup==='L'"
          :isFullScreen="scenarioViewGroup==='L'"
          selection="multiple"
          rowKey="riskAssessmentTargetId"
          @table-data-change="tableDataChange"
        >
          <template v-slot:suffixTitle>
            <q-btn-group outline class="scenario-view-group">
              <q-btn 
                :outline="scenarioViewGroup!=='L'"
                :color="scenarioViewGroup==='L' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="view_list"
                @click="listView"
              >
                <q-tooltip>
                  목록형
                </q-tooltip>
              </q-btn>
              <q-btn 
                :outline="scenarioViewGroup!=='C'"
                :color="scenarioViewGroup==='C' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="space_dashboard"
                @click="cardView"
              >
                <q-tooltip>
                  카드형
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='src'">
              <q-btn 
                round unelevated 
                size="6px"
                color="amber" 
                icon="search"
                @click="srcClick(props.row, props.pageIndex)" />
            </template>
            <template v-if="col.name==='customCol' && props.row['sopImprovementIds'] && props.row['ibmStepNames']">
              <q-chip
                v-for="(item, index) in $comm.ibmTagItems(props.row)"
                :key="index"
                :color="item.color"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click="linkClick(item, props.row)">
                {{item.title}}
                <c-assess-tooltip 
                  :ibmTooltip="item.ibmTooltip"
                  :ibmClassCd="item.ibmClassCd"
                />
              </q-chip>
            </template>
            <template v-else-if="col.name==='item'">
              <q-item class="card-scenario-list">
                <q-item-section>
                  <q-item-label class="scenario-card-title">{{props.row['editFlag'] == 'C' ? '작성중' : `${props.row['processName']} [${props.row['sopName']}] - ${props.row['riskHazardClassCd']}`}}</q-item-label>
                </q-item-section>
                <q-item-section side style="padding-right: 0px;">
                  <q-btn 
                    v-if="props.row['editFlag'] !== 'C'"
                    unelevated  dense
                    color="primary"
                    label="▶"
                    @click.stop="getDetail(props.row.riskAssessmentTargetId)" />
                </q-item-section>
              </q-item>
            </template>
            <template v-else>
            </template>
            <template v-if="col.name==='picture'">
              <q-btn
                class="tableinnerBtn"
                flat
                align="center"
                color="blue-6"
                label=""
                @click.stop="openImprPicture(props)">
                <template v-slot:default >
                  <template v-if="props.row.editFlag!=='C'">
                    <q-btn 
                      unelevated round dense
                      size="10px"
                      color="primary" 
                      icon="wallpaper" />
                  </template>
                </template>
              </q-btn>
            </template>
            <template v-else-if="col.name==='riskPicture'">
              <q-btn 
                unelevated round dense
                size="10px"
                color="primary" 
                icon="wallpaper"
                @click.stop="openHazardPicture(props)" />
            </template>
          </template>
        </c-table>
      </div>
      <div v-if="scenarioViewGroup==='C'" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 scenario-card">
        <q-form ref="editForm">
          <div class="row">
            <div class="col-5">
              <c-card title="상세정보" class="cardClassDetailForm no-margin">
                <template slot="card-detail">
                  <div class="col-12">
                    <!-- 사진 -->
                    <c-upload-picture
                      height="280px"
                      :attachInfo="attachHazardInfo"
                      :editable="false">
                    </c-upload-picture>
                  </div>
                  <div class="col-4">
                    <!-- 공종 -->
                    <c-text
                      :disabled="true"
                      :editable="editable"
                      label="공종"
                      name="scenario.processName"
                      v-model="scenario.processName">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <!-- 작업 -->
                    <c-text
                      :disabled="true"
                      :editable="editable"
                      label="작업"
                      name="sopName"
                      v-model="scenario.sopName">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <!-- 작업위치 -->
                    <q-btn 
                      round unelevated 
                      size="6px"
                      color="amber" 
                      icon="search"
                      @click="srcClick(scenario, -1)" />
                  </div>
                  <div class="col-8">
                    <!-- 유해위험요인 -->
                    <c-text
                      :disabled="true"
                      :editable="editable"
                      label="유해위험요인(위험한 상황과 사건)"
                      name="riskHazardClassCd"
                      v-model="scenario.riskHazardClassCd">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <!-- 관련법규 -->
                    <c-text
                      :disabled="true"
                      :editable="editable"
                      label="관련법규"
                      name="relationLaw"
                      v-model="scenario.relationLaw">
                    </c-text>
                  </div>
                  <div class="col-8">
                    <!-- 현재안전조치 -->
                    <c-text
                      :disabled="true"
                      :editable="editable"
                      label="현재안전조치"
                      name="currentSafetyMeasures"
                      v-model="scenario.currentSafetyMeasures">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-select
                      :disabled="true"
                      :editable="editable"
                      :isChip="true"
                      type="edit"
                      codeGroupCd="RAM_RISK_TYPE_CD"
                      itemText="codeName"
                      itemValue="code"
                      label="판단결과"
                      v-model="scenario.afterRisk">
                    </c-select>
                  </div>
                  <div class="col-6">
                    <!-- 평가일 -->
                    <c-datepicker
                      :disabled="true"
                      :editable="editable"
                      label="평가일"
                      name="assessDate"
                      v-model="scenario.assessDate"
                    />
                  </div>
                  <div class="col-6">
                    <!-- 평가자 -->
                    <c-field
                      :disabled="true"
                      :editable="editable"
                      :data="scenario"
                      label="평가자"
                      name="assessUserId"
                      v-model="scenario.assessUserId"
                      @username="(val) => { scenario.assessUserName = val }" />
                  </div>
                </template>
              </c-card>
            </div>
            <div class="col-7">
              <c-card title="개선정보" class="cardClassDetailForm no-margin">
                <template slot="card-detail">
                  <div class="col-6">
                    <!-- 사진 -->
                    <c-upload-picture
                      height="270px"
                      :attachInfo="attachBeforeInfo"
                      :editable="false">
                    </c-upload-picture>
                  </div>
                  <div class="col-6">
                    <!-- 사진 -->
                    <c-upload-picture
                      height="270px"
                      isMultiTask
                      :attachInfo="attachAfterInfo"
                      :editable="false">
                    </c-upload-picture>
                  </div>
                    <!-- 현재안전조치 -->
                  <!-- <div class="col-6">
                    <c-textarea
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :rows="3"
                      label="현재안전조치"
                      name="riskOccurrenceCause"
                      v-model="scenario.riskOccurrenceCause">
                    </c-textarea>
                  </div> -->
                  <!-- <div class="col-8">
                    <c-radio
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :comboItems="improveFlagItems"
                      label="개선진행여부"
                      name="improveFlag"
                      v-model="scenario.improveFlag"
                      @datachange="improveFlagChange">
                    </c-radio>
                  </div> -->
                  <div class="col-12">
                    <!-- 개선 목록 -->
                    <c-table
                      title="개선 목록"
                      :columns="gridImpr.columns"
                      :data="scenario.imprs"
                      :editable="false"
                      :hideBottom="true"
                      :gridHeight="gridImpr.height"
                      @linkClick="(row) => linkClick(row, scenario)"
                    >
                    </c-table>
                  </div>
                    <!-- 추가 리스크관리 계획 -->
                  <!-- <div class="col-4">
                    <c-textarea
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :rows="3"
                      label="추가 리스크관리 계획"
                      name="currentSafetyMeasures"
                      v-model="scenario.currentSafetyMeasures">
                    </c-textarea>
                  </div> -->
                </template>
              </c-card>
            </div>
          </div>
        </q-form>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'risk-assess-scenario',
  props: {
    actionData: {
      type: Object,
      default: () => ({
        plantCd: '',
        scenarioList: [], // 회의 목록
      }),
    },
    param: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
        stepCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    planUpdateBtnData: {
      title: '계획',
      flag: false,
      research: '',
      planResearch: '',
    },
    updateBtnData: {
      title: '평가',
      flag: false,
      research: '',
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'assessVendorId' },
          { index: 1, colName: 'processName' },
          // { index: 1, colName: 'groupMdmSopId' },
        ],
        columns: [],
        data: [],
        height: '600px'
      },
      editable: true,
      scenario: {
        riskAssessmentTargetId: '',  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정
        beforeFrequency: 0,
        beforeStrength: 0,
        beforeRisk: 0,
        afterFrequency: 0,
        afterStrength: 0,
        afterRisk: null,
        groupMdmSopId: '', 
        mdmSopId: '', 
        sopName: '', 
        processAssessStepCd: '', 
        constructVendorCd: '', 
        workerCount: '', 
        currentSafetyMeasures: '', 
        assessVendorId: '', 
        assessVendorName: '', 
        riskOccurrenceCause: '', 
        riskOccurrenceResult: '', 
        riskHazardClassCd: null, 
        damageTypeCd: null,
        assessDate: '',  // 평가일
        assessUserId: '',  // 평가자 ID
        assessUserName: '',  // 평가자명
        vendorFlag: null, // 업체 작성 여부
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        imprs: [], // 개선목록
      },
      colorItems: [],
      requestImprRow: null,
      imprData: null,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      attachHazardInfo: {
        isSubmit: '',
        taskClassCd: 'RISK_HAZARD',
        taskKey: '',
      },
      attachBeforeInfo: {
        isSubmit: '',
        taskClassCd: 'SCENARIO_BEFORE',
        taskKey: '',
      },
      attachAfterInfo: {
        isSubmit: '',
        task: []
      },
      improveFlagItems: [
        { code: 'X', codeName: '상관없음', },
        { code: 'Y', codeName: '개선진행', },
        { code: 'N', codeName: '유지', },
      ],
      gridImpr: {
        columns: [
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: '진행단계',
            align: 'center',
            style: 'width:85px',
            sortable: true
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '개선담당업체',
            align: 'center',
            style: 'width:85px',
            sortable: true,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:60px',
            sortable: true
          },
        ],
        height: '250px'
      },
      rowIndex: 0,
      isSaveUnit: false,
      isSave: false,
      listImprUrl: '',
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isComplete: false,
      scenarioViewGroup: 'L',
      saveUnitUrl: '',
      vendorCd: '',
      vendorName: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 180) + 'px' : '640px'
    },
    gridColumns() {
      let cols = [];
      if (this.scenarioViewGroup === 'L') {
        cols = [
          {
            name: 'assessVendorName',
            field: 'assessVendorName',
            label: '업체',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            innerBtn: true,
          },
          {
            name: 'sopName',
            field: 'sopName',
            label: '작업',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'src',
            field: 'src',
            label: '작업<br/>위치',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'riskHazardClassCd',
            field: 'riskHazardClassCd',
            label: '유해·위험요인<br/>(위험한 상황과 사건)',
            align: 'left',
            style: 'width:250px',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'riskPicture',
            field: 'riskPicture',
            label: '사진',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false,
          },
          
          {
            name: 'relationLaw',
            field: 'relationLaw',
            label: '관련법규',
            align: 'left',
            style: 'width:130px',
            sortable: false,
            type: 'text'
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            label: '현재안전조치',
            align: 'left',
            style: 'width:170px',
            sortable: false,
            type: 'textarea'
          },
          {
            name: 'afterRisk',
            field: 'afterRisk',
            label: '판단결과',
            align: 'center',
            style: 'width:90px',
            type: 'select',
            codeGroupCd: 'RAM_RISK_TYPE_CD',
            isChip: true,
            sortable: false,
          },
          {
            name: 'picture',
            field: 'picture',
            label: '개선<br/>전/후<br/>사진',
            align: 'center',
            type: 'custom',
            style: 'width:50px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:170px',
            sortable: false,
            type: 'textarea'
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선',
            align: 'center',
            style: 'width:160px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            label: '평가일',
            align: 'center',
            type: 'date',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            label: '평가자',
            align: 'center',
            type: 'user',
            userId: 'assessUserId',
            style: 'width:120px',
            sortable: false,
          },
        ]
      } else {
        cols = [
          {
            name: 'item',
            field: 'item',
            label: '평가내용',
            align: 'left',
            sortable: false,
            type: 'custom'
          },
        ]
      }
      return cols;
    },
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.saas.scenario.list.url
      this.detailUrl = selectConfig.saas.scenario.get.url
      
      if(this.$store.getters.user.innerFlag === 'N') {
        this.vendorCd = this.$store.getters.user.deptCd
        this.vendorName = this.$store.getters.user.deptName
      } 
      // list setting
      this.getList()
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        riskAssessmentPlanId: this.param.riskAssessmentPlanId,
      }
      this.$http.request((_result) => {
        this.actionData.scenarioList = _result.data;
      },);
    },
    getDetail(riskAssessmentTargetId) {
      this.$http.url = this.$format(this.detailUrl, riskAssessmentTargetId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.scenario, _result.data);
        this.$set(this.attachHazardInfo, 'taskKey', this.scenario.riskAssessmentTargetId)
        this.$set(this.attachBeforeInfo, 'taskKey', this.scenario.riskAssessmentTargetId)
        // this.$set(this.attachAfterInfo, 'taskKey', this.scenario.riskAssessmentTargetId)
        this.$set(this.attachAfterInfo, 'task', this.$_.map(this.scenario.imprs, impr => {
          return {
            taskClassCd: 'IBM_AFTER',
            taskKey: impr.sopImprovementId,
          }
        }))
      },);
    },
    getImprs() {
      if (this.scenario.riskAssessmentTargetId) {
        this.$http.url = this.listImprUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          riskAssessmentTargetId: this.scenario.riskAssessmentTargetId,
        }
        this.$http.request((_result) => {
          console.log(_result.data)
          this.$set(this.scenario, 'imprs', _result.data)
        },);
      }
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
          }
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.getImprs();
      }
    },
    datachange(row) {
      if (row.editFlag !== 'C') {
        row.editFlag = 'U'
        row.chgUserId = this.$store.getters.user.userId
      }
    },
    closeMapPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.type === 'APPLY') {
        this.row.maps = data.maps;
        this.row.deleteMaps = data.deleteMaps;
        
        if (this.row.editFlag !== 'C') {
          this.row.editFlag = 'U'
          this.row.chgUserId = this.$store.getters.user.userId
        }
      }
    },
    tableDataChange(props, col) {
      if (col.name === 'beforeFrequency') {
        this.$set(props.row, 'beforeRisk', props.row.beforeFrequency * props.row.beforeStrength)
      } else if (col.name === 'beforeStrength') {
        this.$set(props.row, 'beforeRisk', props.row.beforeFrequency * props.row.beforeStrength)
      } else if (col.name === 'afterFrequency') {
        this.$set(props.row, 'afterRisk', props.row.afterFrequency * props.row.afterStrength)
      } else if (col.name === 'afterStrength') {
        this.$set(props.row, 'afterRisk', props.row.afterFrequency * props.row.afterStrength)
      }
    },
    listView() {
      this.scenarioViewGroup = 'L'
    },
    cardView() {
      this.scenarioViewGroup = 'C'
    },
    saveScenarioUnit() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.scenario.chgUserId = this.$store.getters.user.userId

              this.isSaveUnit = !this.isSaveUnit
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveScenarioUnitCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    openHazardPicture(props) {
      this.popupOptions.title = '위험요인 사진';
      this.popupOptions.param = {
        riskAssessmentTargetId: props.row.riskAssessmentTargetId,
        disabled: true,
      }
      this.popupOptions.target = () => import(`${'@/pages/saas/action/riskHazardPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPicturePopup;
    },
    closeHazardPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = '개선 사진';
      this.popupOptions.param = {
        riskAssessmentTargetId: props.row.riskAssessmentTargetId,
        sopImprovementIds: props.row.sopImprovementIds,
        disabled: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/saas/action/actionScenarioImprPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
<style lang="sass">
.scenario-chip i 
  margin-right: -0.2em !important

.scenario-view-group 
  button
    margin: 0 !important

.scenario-card-title
  margin-left: -5px
  font-size: 0.8rem

.scenario-card-side
  text-align: center
  align-self: center
  // flex-basis: 50%

.card-risk-item
  padding-top: 0px
  padding-bottom: 0px
  min-height: auto
.card-risk-info
  width: auto
  min-width: 0
  max-width: 100%
  flex: 10000 1 0%
  display: flex
  border-radius: 10px
  font-size: 1rem
  height: 30px
.before-risk
  background: #3A98B9
  margin-right: 5px
.after-risk
  background: #3A98B9
  margin-left: 5px
.risk-number
  font-size: 1.3rem

.detail-risk
  font-size: 0.8rem
  font-weight: 600
.riskAssessmentTargetId-num
  font-size: 1rem
  font-weight: 700
  color: #e63946

.card-scenario-list
  padding: 0px 16px !important
  min-height: 28px
</style>
