var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            class: [
              _vm.scenarioViewGroup === "L"
                ? "col-12"
                : "col-xs-12 col-sm-6 col-md-4 col-lg-4",
              "scenario-list",
            ],
          },
          [
            _c("c-table", {
              ref: "table",
              attrs: {
                title: "평가대상 목록",
                columns: _vm.gridColumns,
                gridHeight: _vm.setheight,
                data: _vm.actionData.scenarioList,
                merge: _vm.scenarioViewGroup === "L" ? _vm.grid.merge : [],
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: false,
                isExcelDown: _vm.scenarioViewGroup === "L",
                isFullScreen: _vm.scenarioViewGroup === "L",
                selection: "multiple",
                rowKey: "riskAssessmentTargetId",
              },
              on: { "table-data-change": _vm.tableDataChange },
              scopedSlots: _vm._u([
                {
                  key: "suffixTitle",
                  fn: function () {
                    return [
                      _c(
                        "q-btn-group",
                        {
                          staticClass: "scenario-view-group",
                          attrs: { outline: "" },
                        },
                        [
                          _c(
                            "q-btn",
                            {
                              attrs: {
                                outline: _vm.scenarioViewGroup !== "L",
                                color:
                                  _vm.scenarioViewGroup === "L"
                                    ? "indigo"
                                    : "grey",
                                size: "11px",
                                label: "",
                                icon: "view_list",
                              },
                              on: { click: _vm.listView },
                            },
                            [_c("q-tooltip", [_vm._v(" 목록형 ")])],
                            1
                          ),
                          _c(
                            "q-btn",
                            {
                              attrs: {
                                outline: _vm.scenarioViewGroup !== "C",
                                color:
                                  _vm.scenarioViewGroup === "C"
                                    ? "indigo"
                                    : "grey",
                                size: "11px",
                                label: "",
                                icon: "space_dashboard",
                              },
                              on: { click: _vm.cardView },
                            },
                            [_c("q-tooltip", [_vm._v(" 카드형 ")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "src"
                        ? [
                            _c("q-btn", {
                              attrs: {
                                round: "",
                                unelevated: "",
                                size: "6px",
                                color: "amber",
                                icon: "search",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.srcClick(
                                    props.row,
                                    props.pageIndex
                                  )
                                },
                              },
                            }),
                          ]
                        : _vm._e(),
                      col.name === "customCol" &&
                      props.row["sopImprovementIds"] &&
                      props.row["ibmStepNames"]
                        ? _vm._l(
                            _vm.$comm.ibmTagItems(props.row),
                            function (item, index) {
                              return _c(
                                "q-chip",
                                {
                                  key: index,
                                  staticStyle: {
                                    "margin-bottom": "4px !important",
                                  },
                                  attrs: {
                                    color: item.color,
                                    clickable: true,
                                    "text-color": "white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkClick(item, props.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(item.title) + " "),
                                  _c("c-assess-tooltip", {
                                    attrs: {
                                      ibmTooltip: item.ibmTooltip,
                                      ibmClassCd: item.ibmClassCd,
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          )
                        : col.name === "item"
                        ? [
                            _c(
                              "q-item",
                              { staticClass: "card-scenario-list" },
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { staticClass: "scenario-card-title" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            props.row["editFlag"] == "C"
                                              ? "작성중"
                                              : `${props.row["processName"]} [${props.row["sopName"]}] - ${props.row["riskHazardClassCd"]}`
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  {
                                    staticStyle: { "padding-right": "0px" },
                                    attrs: { side: "" },
                                  },
                                  [
                                    props.row["editFlag"] !== "C"
                                      ? _c("q-btn", {
                                          attrs: {
                                            unelevated: "",
                                            dense: "",
                                            color: "primary",
                                            label: "▶",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.getDetail(
                                                props.row.riskAssessmentTargetId
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : void 0,
                      col.name === "picture"
                        ? [
                            _c("q-btn", {
                              staticClass: "tableinnerBtn",
                              attrs: {
                                flat: "",
                                align: "center",
                                color: "blue-6",
                                label: "",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.openImprPicture(props)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        props.row.editFlag !== "C"
                                          ? [
                                              _c("q-btn", {
                                                attrs: {
                                                  unelevated: "",
                                                  round: "",
                                                  dense: "",
                                                  size: "10px",
                                                  color: "primary",
                                                  icon: "wallpaper",
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : col.name === "riskPicture"
                        ? [
                            _c("q-btn", {
                              attrs: {
                                unelevated: "",
                                round: "",
                                dense: "",
                                size: "10px",
                                color: "primary",
                                icon: "wallpaper",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.openHazardPicture(props)
                                },
                              },
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm.scenarioViewGroup === "C"
          ? _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-6 col-md-8 col-lg-8 scenario-card",
              },
              [
                _c("q-form", { ref: "editForm" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-5" },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm no-margin",
                            attrs: { title: "상세정보" },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("c-upload-picture", {
                                    attrs: {
                                      height: "280px",
                                      attachInfo: _vm.attachHazardInfo,
                                      editable: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "공종",
                                      name: "scenario.processName",
                                    },
                                    model: {
                                      value: _vm.scenario.processName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "processName",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.processName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "작업",
                                      name: "sopName",
                                    },
                                    model: {
                                      value: _vm.scenario.sopName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.scenario, "sopName", $$v)
                                      },
                                      expression: "scenario.sopName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-2" },
                                [
                                  _c("q-btn", {
                                    attrs: {
                                      round: "",
                                      unelevated: "",
                                      size: "6px",
                                      color: "amber",
                                      icon: "search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.srcClick(_vm.scenario, -1)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-8" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "유해위험요인(위험한 상황과 사건)",
                                      name: "riskHazardClassCd",
                                    },
                                    model: {
                                      value: _vm.scenario.riskHazardClassCd,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "riskHazardClassCd",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.riskHazardClassCd",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "관련법규",
                                      name: "relationLaw",
                                    },
                                    model: {
                                      value: _vm.scenario.relationLaw,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "relationLaw",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.relationLaw",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-8" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "현재안전조치",
                                      name: "currentSafetyMeasures",
                                    },
                                    model: {
                                      value: _vm.scenario.currentSafetyMeasures,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "currentSafetyMeasures",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scenario.currentSafetyMeasures",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("c-select", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      isChip: true,
                                      type: "edit",
                                      codeGroupCd: "RAM_RISK_TYPE_CD",
                                      itemText: "codeName",
                                      itemValue: "code",
                                      label: "판단결과",
                                    },
                                    model: {
                                      value: _vm.scenario.afterRisk,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.scenario, "afterRisk", $$v)
                                      },
                                      expression: "scenario.afterRisk",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-datepicker", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "평가일",
                                      name: "assessDate",
                                    },
                                    model: {
                                      value: _vm.scenario.assessDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "assessDate",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.assessDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-field", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      data: _vm.scenario,
                                      label: "평가자",
                                      name: "assessUserId",
                                    },
                                    on: {
                                      username: (val) => {
                                        _vm.scenario.assessUserName = val
                                      },
                                    },
                                    model: {
                                      value: _vm.scenario.assessUserId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.scenario,
                                          "assessUserId",
                                          $$v
                                        )
                                      },
                                      expression: "scenario.assessUserId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-7" },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm no-margin",
                            attrs: { title: "개선정보" },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-upload-picture", {
                                    attrs: {
                                      height: "270px",
                                      attachInfo: _vm.attachBeforeInfo,
                                      editable: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("c-upload-picture", {
                                    attrs: {
                                      height: "270px",
                                      isMultiTask: "",
                                      attachInfo: _vm.attachAfterInfo,
                                      editable: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("c-table", {
                                    attrs: {
                                      title: "개선 목록",
                                      columns: _vm.gridImpr.columns,
                                      data: _vm.scenario.imprs,
                                      editable: false,
                                      hideBottom: true,
                                      gridHeight: _vm.gridImpr.height,
                                    },
                                    on: {
                                      linkClick: (row) =>
                                        _vm.linkClick(row, _vm.scenario),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }